<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style=" width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center  tw-items-center tw-self-end  tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="section === 'first'"
        class=" tw-flex card tw-p-3 lg:tw-p-9 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px">
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <div v-for = "(event,index) in events" :key="index" class="tw-flex tw-w-full tw-flex-col">
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-5 tw-cursor-pointer" @click="selectedCamp(event)"
               :class="[selectedCampName === event.campNameTag ? 'selected-camp' : '']">
            <h6 class="event-title"><span class="event-desc">{{event.campNameTag}}</span></h6>
            <h6 class="event-title"><span class="event-desc">{{getDate(event.dateOfCampStart)}}</span></h6>
            <h6 class="event-desc" v-if="$vuetify.breakpoint.mdAndUp"> - </h6>
            <h6 class="event-desc" v-if="$vuetify.breakpoint.mdAndUp"> {{getDate(event.dateOfCampEnd)}}</h6>
          </div>
        </div>
        <base-button  button-text="Next" width="100%" class="tw-mt-4" @click="handleNext"/>
      </div>

      <div v-if="section === 'second'" class=" tw-flex card tw-p-3 lg:tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
          <p class="camp-title text-uppercase">{{selectedCampData.campNameTag}}</p>
          <p class="camp-address text-uppercase">{{selectedCampData.campAddress.houseNumber}} -
            {{selectedCampData.campAddress.postalCode}},  {{selectedCampData.campAddress.city}}, {{selectedCampData.campAddress.county}}</p>
        <div class="tw-py-5 tw-w-full tw-flex-col">
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center tw-pt-4">
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-title">Camp Start Date</h6>
            </div>
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-desc">{{getDate(selectedCampData.dateOfCampStart)}}</h6>
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center tw-pt-4">
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-title">Camp End Date</h6>
            </div>
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-desc">{{getDate(selectedCampData.dateOfCampEnd)}}</h6>
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center tw-pt-4">
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-title">Sponsors</h6>
            </div>
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-desc">
                <span v-if="selectedCampData.mainSponsor">{{selectedCampData.mainSponsor}} | </span>
                <span v-if="selectedCampData.semiSponsor">{{selectedCampData.semiSponsor}} | </span>
                <span v-if="selectedCampData.standardSponsor">{{selectedCampData.standardSponsor}}  </span>
              </h6>
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center tw-pt-4">
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-title">Application Closing date</h6>
            </div>
            <div class="tw-w-full lg:tw-w-1/2">
              <h6 class="event-desc" v-if="selectedCampData.applicationClosingDate">
                {{getDate(selectedCampData.applicationClosingDate)}}
              </h6>
            </div>
          </div>
          <img :src="selectedCampData.campImagePoster" alt="comp post" class="camp-image tw-mt-4"/>
          <div class="tw-py-4">
            <v-checkbox color="#F66B05" hide-details class="terms" v-model="agreeToCamp">
              <template #label>
                <label class="tw-ml-2"
                >I agree to the
                  <span style="color: #F66B05" class="tw-cursor-pointer">
                      Terms & Conditions</span
                  >
                  of the Camp
                  </label
                >
              </template>
            </v-checkbox>
          </div>
          <base-button :disabled="!agreeToCamp" button-text="Proceed" width="100%" @click="handleProceed"/>
        </div>
      </div>
      <div v-if="section === 'third'" class=" tw-flex card tw-p-3 lg:tw-p-9 tw-justify-start tw-items-start tw-flex-col"
           style="min-height: 384px">
        <h6 class="camp-title">Select Applicant</h6>
        <h6 class="camp-desc tw-py-4">You can only select one candidate at a time</h6>

        <div class="tw-flex tw-w-full tw-flex-col scroll">

          <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-my-4 tw-p-4 tw-cursor-pointer"
               v-for="(applicant,index) in applicants" :key="index"
               :class="[selectedApplicant === applicant ? 'applicant-card-selected' : 'applicant-card']"
              @click="handleSelectedApplicant(applicant)">
            <div class="applicant-avatar text-uppercase">{{applicant.firstname.charAt(0)}}</div>
            <div class="event-title text-capitalize ">{{applicant.firstname}}</div>
            <div class="event-title text-capitalize ">{{applicant.lastname}}</div>
            <div class=" camp-desc text-capitalize">{{applicant.height}} {{applicant.weight}}</div>

          </div>

        </div>
        <base-button button-text="Submit" width="100%" @click="handleSubmit" :loading="submitLoading"/>
      </div>
      <div v-if="section === 'fourth'" class=" tw-flex card tw-p-3 lg:tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
           <img :src="great" alt="great" />
          <p class="title tw-py-5 tw-w-full lg:tw-w-7/12" :style="{color:color}">Great, Application
            Successful</p>
          <p class="camp-desc">Kindly check your email for the event receipt.</p>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import check from "@/assets/check.svg";
import great from "@/assets/great.svg"
import dayjs from "dayjs";
import BaseButton from "@/components/reuseables/BaseButton";
import { applyToEvent, getAllGuardianCandidate, getAllUpcomingEvents } from "@/services/api/APIService";

export default {
  name: "ApplyForCampModal",
  components: { BaseButton  },
  props: {
    dialog: Boolean,
    icon: {
      type: String,
      default: "check",
    },
    title: {
      type: String,
      default: "Upcoming Camp Events",
    },
    description: {
      type: String,
      default: "",
    },
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
    downloadUrl: [String],
    color: {
      type: [String],
      default: "#F66B05",
    },

    loading:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      check,
      great,
      reason:null,
      events:[],
      selectedCampName: "",
      section: "first",
      selectedCampData:{},
      agreeToCamp: false,
      applicants:[],
      selectedApplicant: {},
      submitLoading : false
    };
  },
  methods:{
    handleNext(){
      if (this.section === 'first' && this.selectedCampName){
        this.section = 'second'
      }
      else {
        this.$displaySnackbar({
          message: "Please select an event",
          success: false,
        });
      }
    },
    handleSelectedApplicant(data){
      this.selectedApplicant = data
    },
    handleProceed(){
      this.section = 'third'
    },
    getDate(date){
      return dayjs(date).format("ll")
    },
    selectedCamp(data){
      this.selectedCampName = data.campNameTag
      this.selectedCampData = data
    },
    getAllUpcomingEvent(){
      getAllUpcomingEvents().then(res =>{

        this.events = res.data
      }).catch(() => {
        this.$displaySnackbar({
          message: "Please try again",
          success: false,
        });

      });
    },
    async getAllGuardianCandidate() {
      let parentData = JSON.parse(sessionStorage.getItem("parentData"))
     await getAllGuardianCandidate(parentData.id).then(res =>{
        this.applicants = res.data
      }).catch((err) => {

        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });

      });
    },
    handleSubmit(){
      if (this.selectedApplicant.firstname && this.selectedCampData.campNameTag) {
        this.submitLoading = true
        let parentData = JSON.parse(sessionStorage.getItem("parentData"))
        let applyData = {}
        applyData.guardianId = parentData.id
        applyData.eventAppliedTo= this.selectedCampData.id
        applyData.playerApplying = this.selectedApplicant.id
        applyToEvent(applyData).then(() =>{
          this.section = 'fourth'
          this.submitLoading = false
        }).catch((err) => {
          this.submitLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });

        });

      }
      else {
        this.$displaySnackbar({
          message: "Please select an applicant",
          success: false,
        });
      }
    }
  },
  async created() {
    await this.getAllUpcomingEvent()
    await this.getAllGuardianCandidate()
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 90% !important;
  @media screen and (min-width: 1024px) {
    width: 35%!important;
  }

}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}

.cancel-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  line-height: 24px;
  text-align: left;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.second-btn{
  text-transform: none;
}
.first-btn{
  text-transform: none;
}

.event-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #333333;
}
.event-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #333333;
}

.selected-camp{
  border: 1px solid #F66B05;
  padding: 2rem;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
}

.camp-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #F66B05;
}
.camp-address{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}
.camp-image{
  width: 150px;
  height: auto;
}

.camp-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4F4F4F;
}

.applicant-card{
  width: 100%;
  height: 68px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px rgba(79, 79, 79, 0.1);
  border-radius: 10px;
}

.applicant-card-selected{
  width: 100%;
  height: 68px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px rgba(79, 79, 79, 0.1);
  border-radius: 10px;
  border: 1px solid #F66B05;
}

.applicant-avatar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F66B05;
  color: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.scroll {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30vh;
  height: 30vh;

}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;

}
.scroll::-webkit-scrollbar-thumb {

  background: #c4c4c4 !important;
  //max-height: 20px !important;
  border-radius: 6px;

}
</style>
