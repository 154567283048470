<template>
  <div class="tw-flex tw-w-full ">
    <div class=" tw-flex lg:tw-hidden tw-w-full">
      <div class="tw-flex tw-w-full tw-justify-between">
        <img src="@/assets/logo.png" alt="logo" />
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        temporary
        class="tw-pl-5"

      >
        <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-10">
          <image-avatar :image="loginUserPhoto" :avatar="loginUser ?loginUser.charAt(0):''" :color="color"  />
          <div class="tw-flex tw-items-start tw-justify-start tw-flex-col pl-5 tw-w-full" >
            <h6 class="name" :style="{color:color}">{{loginUser}} </h6>
            <label class="email">{{loginUserEmail}}</label>
          </div>
        </div>

        <div
          :class="selectedTab === tab ? 'selected' : 'select-div'"
          class="my-3    tw-cursor-pointer"
          @click="handleSelectedTab(tab)"
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <v-icon v-if="tab === selectedTab" color="#F66B05"
          >mdi-power-on</v-icon
          >
          <icons
            :name="tab"
            :color="selectedTab === tab ? '#F66B05' : '#4F4F4F'"
          />

          <label
            class="tw-pl-4 text-capitalize"
            :style="{ color: selectedTab === tab ? '#F66B05' : '#4F4F4F' }"
          >{{ tab }}</label
          >
        </div>

        <v-btn text class="add-candidate tw-mt-6" @click="$router.push({name: 'AddCandidate'})">Add Candidate</v-btn>
        <div class="tw-flex  tw-items-center tw-flex-row tw-mt-20 tw-cursor-pointer" @click="handleLogout">
          <icons color="#F66B05" name="logout" />
          <span class="tw-pl-4 text-capitalize" :style="{ color: '#F66B05' }">logout</span>
        </div>
      </v-navigation-drawer>

    </div>

    <div class="tw-hidden lg:tw-flex tw-w-full tw-flex-row tw-items-center header tw-justify-between tw-px-3">
      <div style="width: 689px">
        <v-text-field hide-details solo flat placeholder="Search" >
          <template #append>
            <v-btn text color="#F66B05" >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>

      <v-btn text class="volunteer" @click="handleVolunteer" :loading="volunteerLoading">Volunteer</v-btn>
      <v-divider vertical />
      <v-icon color="#F66B05">mdi-bell-outline</v-icon>

      <v-col sm="3" class="tw-flex tw-items-center tw-flex-row" style="border-right: 1px solid rgba(79, 79, 79, 0.06);
     border-left: 1px solid rgba(79, 79, 79, 0.06); height: 60px;">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs"
                 v-on="on" class="tw-flex tw-w-full tw-flex-row menu-drop-down" >
              <div class="tw-flex tw-items-start tw-justify-start tw-flex-col pl-5 tw-w-full" >
                <h6 class="name" :style="{color:color}">{{loginUser}} <v-icon size="18">mdi-chevron-down</v-icon> </h6>
                <label class="email">{{loginUserEmail}}</label>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item @click="selectedAction(item)"
                         v-for="(item, index) in items"
                         :key="index" >
              <v-list-item-title style="color: black" >{{ item}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <image-avatar :image="loginUserPhoto" :avatar="loginUser ?loginUser.charAt(0):''" :color="color"  />

      </v-col>
      <v-btn text class="volunteer" style="font-size: 14px" @click="handleShowCampModal">Apply for Camp</v-btn>
    </div>
  </div>


</template>

<script>

import { logout } from "@/services/api/AuthApiService";
import ImageAvatar from "@/components/reuseables/ImageAvatar";

import Icons from "@/components/reuseables/Icons";
import { guardianVolunteer } from "@/services/api/APIService";
export default {
  name: "DashboardHeader",
  components: { Icons, ImageAvatar},
  data(){
    return{
      items:["Logout"],
      color: "#F66B05",
      loginUser: "Tega",
      loginUserEmail: "tega@buckland.com",
      loginUserPhoto :null,
      drawer: false,
      selectedTab: "dashboard",
      tabs: [
        "dashboard",
        "apply to camp",
        "profile",
        "settings"
      ],
      volunteerLoading: false
    }
  },
  methods:{
    selectedAction(item) {
      if (item === 'Logout') {
        this.handleLogout()
      }
    },
    handleLogout() {
      logout()
    },
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== 'MainDashboard')
          this.$router.push({ name: "MainDashboard" });
      }
      else if (this.selectedTab === "profile"){
        if (this.$route.name !== 'GuardianProfile'){
          this.$router.push({name : 'GuardianProfile'})
        }
      }
      else if (this.selectedTab === "settings"){
        if (this.$route.name !== "Settings"){
          this.$router.push({name : "Settings"})
        }
      }
      else if (this.selectedTab === "apply to camp"){
        this.$store.dispatch("applyToCamp/setCampModal",true)
      }

      sessionStorage.setItem("dashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("dashboardComponent");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);
    },
    handleVolunteer(){
      this.volunteerLoading = true
      let parentData = JSON.parse(sessionStorage.getItem("parentData"))
      guardianVolunteer(parentData.id).then(res =>{
        this.volunteerLoading = false
        this.$displaySnackbar({
          message: res.data.Detail,
          success: true,
        })
      }).catch((err) => {
        this.volunteerLoading = false
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        })
      })

    },
    handleShowCampModal(){
      this.$store.dispatch("applyToCamp/setCampModal",true)
    }
  },
  created() {
    let parentData = JSON.parse(sessionStorage.getItem("parentData"))
    if (Object.keys(parentData).length){
      this.loginUser = parentData.firstname
      this.loginUserEmail = parentData.email
      this.loginUserPhoto = parentData.profileImage
    }

    let component = sessionStorage.getItem("dashboardComponent");
    if (!component) {
      sessionStorage.setItem("dashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);

      this.handleSelectedTab(this.selectedTab);
    }
  }
};
</script>

<style scoped lang="scss">
.header{
  background-color: #FFFFFF;
  border-radius: 10px;
}

.volunteer{
  width: 128px;
  height: 32px;
  background: #F66B05;
  border-radius: 6px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: #FFFFFF !important;
  text-transform: capitalize;
}

.name{
  font-family: Inter,serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.email{
  font-family: Inter,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #BDBDBD;
  @media screen and (min-width: 1900px){
    font-size: 12px;
  }
}
.menu-drop-down{
  width: 60%;
  @media screen and (max-width: 11450px){
    width: 80%;
  }
}

.select-div {
  height: 50px;
  background: white;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: white;
  flex-direction: row;
  text-transform: capitalize;
}
.select-div:hover {
  height: 50px;
  background: #FFFBF9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.selected {
  height: 50px;
  background: #FFFBF9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  border: none;
  text-transform: capitalize;
}

.logo{
  width: 35px;
  height: 52px;
}

.add-candidate{
  width: 128px;
  height: 32px;
  background: #F66B05;
  border-radius: 6px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: #FFFFFF !important;
  text-transform: capitalize;
}
.parent-avatar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F66B05;
  color: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
</style>