<template>
  <div class="tw-w-full">
    <loader v-if="loading" :loading="loading"/>
    <div class="body" v-else>
      <side-menu/>
      <center-component/>
      <apply-for-camp-modal :dialog="showCampModal" @close="handleCloseApplyToCampModal"/>
    </div>


  </div>
</template>

<script>
import SideMenu from "@/components/reuseables/SideMenu";
import CenterComponent from "@/components/reuseables/CenterComponent";
import { getGuardianById } from "@/services/api/APIService";
import Loader from "@/components/reuseables/Loader";
import ApplyForCampModal from "@/components/reuseables/ApplyForCampModal";
import { mapGetters } from "vuex";
export default {
  name: "DashboardView",
  components: { ApplyForCampModal, Loader, CenterComponent, SideMenu },
  data(){
    return{
      loading : false
    }
  },
  computed:{
    ...mapGetters("applyToCamp", ["showCampModal"]),
  },
  methods:{
    handleCloseApplyToCampModal(){
      this.$store.dispatch("applyToCamp/setCampModal",false)

    },
  },
 async created() {
    this.loading = true
    let userData = JSON.parse(sessionStorage.getItem('userData'))
    if (Object.keys(userData).length){
     await getGuardianById(userData.id).then(res =>{
       this.loading = false
       sessionStorage.setItem("parentData", JSON.stringify(res.data))
     }).catch((err) => {
       this.loading = false
       console.log(err,"in compo err");
       this.$displaySnackbar({
         message: err.response.data.details[0],
         success: false,
       })})
    }
  }
};
</script>

<style scoped lang="scss">
.body{
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: row;
  background: rgba(255, 225, 225, 0.3);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
</style>