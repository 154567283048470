<template>
     <v-col sm="2" class="side-menu tw-p-0 tw-hidden lg:tw-flex">
       <img @click="$router.push({name:'HomePage'})" class="logo tw-mb-16 tw-mt-5 tw-cursor-pointer" src="@/assets/logo.png"  alt="logo"/>
       <div
         :class="selectedTab === tab ? 'selected' : 'select-div'"
         class="my-3   tw-cursor-pointer"
         @click="handleSelectedTab(tab)"
         v-for="(tab, index) in tabs"
         :key="index"
       >
         <v-icon v-if="tab === selectedTab" :color="companyColor"
         >mdi-power-on</v-icon
         >
         <icons
           :name="tab"
           :color="selectedTab === tab ? companyColor : '#4F4F4F'"
         />
         <label
           class="tw-pl-4 text-capitalize"
           :style="{ color: selectedTab === tab ? companyColor : '#4F4F4F' }"
         >{{ tab }}</label
         >
       </div>

       <v-btn text class="add-candidate tw-mt-6" @click="$router.push({name: 'AddCandidate'})">Add Candidate</v-btn>
       <div class="tw-flex  tw-items-center tw-flex-row tw-mt-20 tw-cursor-pointer" @click="handleLogout">
         <icons color="#F66B05" name="logout" />
         <span class="tw-pl-4 text-capitalize" :style="{ color: '#F66B05' }">logout</span>
       </div>
     </v-col>


</template>

<script>
import Icons from "./Icons";
import { mapGetters } from "vuex";
import {logout} from "@/services/api/AuthApiService";

export default {
  name: "SideMenu",
  components: { Icons },
  props: {
    companyColor: {
      type: [String],
      default: "#F66B05",
    },
  },
  data() {
    return {
      selectedTab: "dashboard",
      tabs: [
        "dashboard",
        "apply to camp",
        "profile",
        "settings"
      ],
      drawer : false
    };
  },
  computed: {
    ...mapGetters("dashboardComponent", ["currentComponent"]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== 'MainDashboard')
        this.$router.push({ name: "MainDashboard" });
      }
      else if (this.selectedTab === "profile"){
        if (this.$route.name !== 'GuardianProfile'){
          this.$router.push({name : 'GuardianProfile'})
        }
      }
      else if (this.selectedTab === "settings"){
        if (this.$route.name !== "Settings"){
          this.$router.push({name : "Settings"})
        }
      }
      else if (this.selectedTab === "apply to camp"){
        this.$store.dispatch("applyToCamp/setCampModal",true)
      }

      sessionStorage.setItem("dashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("dashboardComponent");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);
    },
    handleLogout(){
      logout()
    }
  },
  created() {
    let component = sessionStorage.getItem("dashboardComponent");
    if (!component) {
      sessionStorage.setItem("dashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);

      this.handleSelectedTab(this.selectedTab);
    }
  },
};
</script>

<style scoped lang="scss">
.side-menu {

  background: white;
  width: 100%;
  min-height: 100vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 0 0 3rem;

  flex-direction: column;
  z-index: 5;

}

.select-div {
  height: 50px;
  background: white;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: white;
  flex-direction: row;
  text-transform: capitalize;
}
.select-div:hover {
  height: 50px;
  background: #FFFBF9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.selected {
  height: 50px;
  background: #FFFBF9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  border: none;
  text-transform: capitalize;
}

.logo{
  width: 35px;
  height: 52px;
}

.add-candidate{
  width: 128px;
  height: 32px;
  background: #F66B05;
  border-radius: 6px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: #FFFFFF !important;
  text-transform: capitalize;
}
</style>
