<template>
  <div class="d-flex align-center justify-center">
    <v-progress-circular
        indeterminate
        :color="color"
        class="mt-16"
    />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color:{
      type: [String],
      default: '#F66B05'
    }
  },
}
</script>

<style scoped>

</style>