<template>
<v-col sm="12" lg="10" class="center-component">
  <dashboard-header />
  <router-view></router-view>
</v-col>
</template>

<script>
import DashboardHeader from "@/components/reuseables/DashboardHeader";

export default {
  name: "CenterComponent",
  components: {  DashboardHeader },
  data(){
    return{
      loading : false
    }
  },
  created() {

  }
};
</script>

<style scoped lang="scss">
.center-component{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: available;
  padding:  2rem 5rem;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
}
</style>